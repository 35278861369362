import * as React from "react"
import WindowSize from "../../utils/windowSize"

import * as styles from './layout.module.css'

const LayoutComponent = ({ children }) => {
  return (
    <WindowSize>
      <div className={styles.layer}>
        <main className={styles.main}>
          {children}
        </main>
      </div>
    </WindowSize>
  )
}

export default LayoutComponent
