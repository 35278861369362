import React, { useEffect, createContext, useState } from "react"

const initialSize = {
  width: 0,
  height: 0
};

export const WindowSizeContext = createContext(initialSize);

const WindowSize = ({ children }) => {
  const [size, setSize] = useState(initialSize);

  useEffect(() => {
    const onResize = () => setSize({
      width: window.innerWidth,
      height: window.innerHeight
    });

    window.addEventListener('resize', onResize);

    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <WindowSizeContext.Provider value={size}>
      {children}
    </WindowSizeContext.Provider>
  );
};

export default WindowSize;
